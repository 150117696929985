import React from "react";
import styled from "styled-components";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";
import { Headline, Text } from "../../../common/headline";

const Container = styled.div`
width: 100%;
padding: 4rem;
background: #eee;

@media (max-width: 768px) {
  padding:  2rem 1.5rem;
`;

const Line = styled.div`
  border-bottom: 2px solid #f46d2b;
  width: 150px;
  margin: 0 auto;
  margin-top: -15px;
  margin-bottom: 15px;
`;

const Customer = () => {
  const intl = useIntl();
  return (
    <Container>
      <Headline type="h1" textAlign="center">
        {intl.formatMessage(i18n.customer.title)}{" "}
      </Headline>
      <Line />
      <Text fontSize="m" maxWidth="1100px" margin="2rem auto 0 auto">
        {intl.formatMessage(i18n.customer.text1)}
      </Text>
      <br />
      <Text fontSize="m" maxWidth="1100px" margin="0 auto">
        {intl.formatMessage(i18n.customer.text2)}
      </Text>
    </Container>
  );
};

export default Customer;
