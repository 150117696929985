import { defineMessages } from "react-intl"

const scope = "aboutUs"

export default defineMessages({
  who: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Das sind wir",
    },
    text: {
      id: `${scope}.text`,
      defaultMessage:
        "Als freier und herstellerunabhängiger Dienstleister sind wir Ihr Allrounder für die Reparatur von Elektronik. Wir sind begeistert von Technologie und lieben unsere Arbeit. Innovation und ein Höchstmaß an Professionalität sind das Markenzeichen des TMC-TEC-Reparaturservices.",
    },
  },
  customer: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Unsere Kunden",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Der Endverbraucher steht für uns an vorderster Stelle. Egal ob Geschäfts- und Privatkunden oder kleine und mittelständische Unternehmen – unsere Kunden kommen immer zuerst.",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage:
        " Hierbei profitieren Sie von unserer jahrelangen Erfahrung bei der Reparatur von Notebooks und anderen Geräten. Wir garantieren Ihnen eine schnelle und reibungslose Durchführung Ihres Auftrages. Beim Abschluss eines entsprechenden Servicevertrages ist es sogar möglich, ein genaues Fertigstellungsdatum zu vereinbaren. Sollte doch einmal etwas schiefgehen, stellen wir ihnen gerne ein Ersatzgerät zur Verfügung. Innovative Lösungen rund um die Reparatur Ihrer Geräte sind unsere Spezialität.",
    },
  },
  team: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Team",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Auch nach mehr als 14 Jahren sind wir im Herzen bis heute ein Start-up mit viel Ehrgeiz und großen Ambitionen. ",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage:
        "Kompetenz, Erfahrung und Leidenschaft sind unser Antrieb und die Grundlage unserer Unternehmenskultur. Hiermit machen wir das Unmögliche möglich. Bei der Auswahl unserer Mitarbeiter setzen wir daher neben der fachlichen Qualifikation auch auf eine starke Persönlichkeit. Zielstrebigkeit, eigenverantwortliches Arbeiten und Teamgeist sind hierbei Eigenschaften, die wir besonders schätzen.",
    },
    sign: {
      id: `${scope}.sign`,
      defaultMessage: "Anna Czyzewski",
    },
    position: {
      id: `${scope}.position`,
      defaultMessage: "HR Manager",
    },
    extra: {
      id: `${scope}.extra`,
      defaultMessage:
        "Wir verhandeln und kooperieren täglich mit verschiedenen Experten und Servicepartnern. Auf diese Weise ist es uns möglich, unsere Produkte stetig zu verbessern und weiterzuentwickeln.",
    },
  },
  why: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Warum wir ?",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Für unsere Arbeit setzen wir ausschließlich auf modernste Technik, um eine höchstmögliche Qualität zu gewährleisten. Hierzu investieren wir jedes Jahr in neue Maschinen sowie Prüf-und Messgeräte. Auch unsere Mitarbeiter halten wir durch regelmäßige Schulungen fortwährend auf dem neuesten Stand. Auf diese Weise gelingt es uns, unsere Leistungen immer weiter zu verbessern. ",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage:
        "Im Gegensatz zur Konkurrenz bieten wir unseren Kunden zudem zahlreiche Extras. Hierzu gehören unsere hoch professionelle Arbeit, ein enger Kundenkontakt, eine kostenlose Fehlerdiagnose und absolut faire Preise. Die niedrigen Kosten sind hierbei das Ergebnis hervorragender Beziehungen zu diversen Teilelieferanten und selbst entwickelter Reparaturmethoden. Auch ein extremes hohes Maß an Zuverlässigkeit und eine sehr kurze Bearbeitungszeit gehören zu unseren Alleinstellungsmerkmalen. In nur ein bis drei Werktagen ist Ihr Gerät in der Regel umfassend repariert. ",
    },
  },
  certificate: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Zertifizierungen",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Mithilfe der Integration eigener Online-Portale und Datenbanken in unsere zentralen Systeme senken wir den Verwaltungsaufwand um 70% pro Auftrag. Leistungsstarke Tools und Verfahren bieten unseren Technikern Sicherheit und sorgen dafür, dass unsere Kunden über jeden Schritt informiert bleiben.",
    },

    text3: {
      id: `${scope}.text3`,
      defaultMessage:
        "Sämtliche Reparaturen erfolgen dabei nach den Vorgaben der IPC-Normen und -richtlinien. ",
    },
  },
})
