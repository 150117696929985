import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import SOLDA_LOGO from "../../../../logos/solda-logo-white.svg"

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "ubahn.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#FAC74E`}
    >
      <Hero src={SOLDA_LOGO} alt="Solda logo" />
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  height: 500px;
  margin: -15px auto 15px auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 768px) {
    height: 200px;
    width: 100vw;
    margin-left: -1.5rem;
    margin-top: -3.1rem;
    background-position: top center;
  }
`
const Hero = styled.img`
  box-sizing: border-box;
  width: 150px;
  display: flex;
  justify-content: flex-start;
  padding: 1.5rem 2rem;
  user-select: none;
`

export default StyledBackgroundSection
