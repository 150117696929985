import React, { useState } from "react"
import { Container, Play, Video, Content } from "./styles"
import video from "../start-video/tmc-short.mp4"
import mainVideo from "./tmc.mp4"
import { FaRegPlayCircle } from "react-icons/fa"


const StartVideo = () => {
  const [isOpen, setOpen] = useState(false)

  const ShowVideo = () => setOpen(true)

  return (
    <Container>
    
      <Content>
        {!isOpen && (
          <Video autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </Video>
        )}
        {!isOpen && (
          <Play onClick={ShowVideo}>
            <FaRegPlayCircle size="5rem" />
          </Play>
        )}

        {isOpen && <MainVideo />}
      </Content>
 
    </Container>
  )
}

const MainVideo = () => {
  return (
    <Video controls autoPlay>
      <source src={mainVideo} type="video/mp4" />
    </Video>
  )
}

export default StartVideo
