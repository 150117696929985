import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-family: "Open Sans";
  width: ${props => props.width};
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  font-weight: ${props => props.fontWeight};
  border-bottom: ${props => props.borderBottom};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  @media (max-width: 768px) {
    font-size: ${props => props.fontMobSize};
  }
`

const StyledText = styled.div`
  font-family: "Open Sans";
  font-size: ${props => (props.fontSize === "m" ? "1.5rem" : "1rem")};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  max-width: ${props => props.maxWidth};
  font-weight: 300;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`

export const Headline = ({
  type,
  fontWeight = "600",

  children,
  ...props
}) => {
  return (
    <Container>
      <Title as={type} fontWeight={fontWeight} {...props}>
        {children}
      </Title>
    </Container>
  )
}

export const Text = ({ children, lineHeight = "5", ...props }) => {
  return (
    <Container>
      <StyledText {...props}>{children}</StyledText>
    </Container>
  )
}

export const StyleText = ({ fontSize = "1.5rem", children, ...props }) => {
  return <Text style={{ fontSize, ...props }}> {children} </Text>
}
