import React from "react"
import styled from "styled-components"
import { Image } from "../shared-components/images"

const Container = styled.div`
  max-width: 1600px;
  width: ${props=> props.width}%;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  position: scroll;

  @media (max-width: 768px) {
    width: 90vw;
  }
`

const ImgHolder = ({source, alt, width}) => {
  return (
    <Container width={width}>
      <Image source={source} alt={alt} />
    </Container>
  )
}

export default ImgHolder 
