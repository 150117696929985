import React from "react"
import styled from "styled-components"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

import { Headline, Text } from "../../../common/headline"
import StyledCustomerBackgroundSection from "./hero-image"
import StyledBackgroundSection from "../../../page-components/aboutUs/hero-image"

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 4rem 0 4rem;
  background: #eee;

  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }
`
const Line = styled.div`
  border-bottom: 2px solid #f46d2b;
  width: 150px;
  margin: 0 auto;
  margin-top: -15px;
  margin-bottom: 15px;
`

const AbtUs = () => {
  const intl = useIntl()
  return (
    <Container>
      <StyledBackgroundSection />
      <Headline type="h1" textAlign="center" margin="4rem auto 1.3rem auto">
        {intl.formatMessage(i18n.who.title)}
      </Headline>
      <Line />
      <Text fontSize="m" maxWidth="1100px" margin="1.5rem auto">
        {intl.formatMessage(i18n.who.text)}
      </Text>
      <br />
      <StyledCustomerBackgroundSection />
    </Container>
  )
}

export default AbtUs
