import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"


const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "chess.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#243780`}
    ></BackgroundImage>
  )
}

const StyledWhySection = styled(BackgroundSection)`
  width: 100%;
  height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 768px) {
    height: 200px;
    width: 100vw;
    margin-left: -1.5rem;
    background-position: top center;
  }
`

export default StyledWhySection
