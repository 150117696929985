import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"
import { Headline, Text } from "../../../common/headline"
import styled from "styled-components"
import { Image } from "../../../common/shared-components/images"

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 4rem;
  background: #eee;
  padding-top: 1rem;

  @media (max-width: 425px) {
    padding: 0 1.5rem;
  }
`

const Img = styled.div`
  width: 500px;
  padding-top: 2rem;

  @media (max-width: 1300px) {
    margin-right: -2rem;
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 425px) {
    margin-right: -3rem;
    width: 200px;
    margin-top: 3rem;
  }
`

const ImgText = styled.p`
  font-family: "Open Sans";
  font-size: 1.5rem;
  margin: 0;
  line-height: 1.2;
  width: 100%;
  color: white;
  font-weight: 300;
  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
  @media (max-width: 560px) {
    font-size: 1rem;
  }
  @media (max-width: 375px) {
    font-size: 0.8rem;
  }
`

const ImgName = styled.p`
  width: 180px;
  display: grid;
  place-items: center;
  margin: 0;
  font-family: "zeyada";
  font-display: swap;
  color: white;
  margin-left: 0.8rem;
  font-size: 2rem;
  margin-bottom: -1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
`

const ImgPosition = styled(ImgName)`
  font-family: "Open Sans";
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 0.9rem;

  @media (max-width: 900px) {
    font-size: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`

const BottomText = styled.div`
  width: 180px;
  margin-top: 2rem;
  @media (max-width: 450px) {
    margin-top: 0.5rem;
  }
`

const Line = styled.div`
  border-bottom: 2px solid #f46d2b;
  width: 150px;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 15px;
`
const NewImgContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
  background: #b6a49c;
  @media (max-width: 1300px) {
    width: 100vw;
    margin-left: -4rem;
  }
  @media (max-width: 900px) {
  }

  @media (max-width: 768px) {
    grid-gap: 0;
  }
  @media (max-width: 425px) {
    width: 100vw;
    margin-left: -1.5rem;
  }
`

const NewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6rem;
  @media (max-width: 1300px) {
    margin-left: 2rem;
    width: 120%;
    z-index: 1;
  }
  @media (max-width: 900px) {
  }

  @media (max-width: 768px) {
    width: 120%;
    margin-left: 2vw;
  }
  @media (max-width: 450px) {
    width: 130%;
  }

  @media (max-width: 425px) {
    width: 120%;
    z-index: 1;
    margin-top: 1rem;
  }
`

const Team = () => {
  const intl = useIntl()
  return (
    <Container>
      <Headline type="h2" textAlign="center" margin="2rem auto">
        {intl.formatMessage(i18n.team.title)}
      </Headline>
      <Line />
      <Text fontSize="m" maxWidth="1100px" margin="2rem auto">
        {intl.formatMessage(i18n.team.text1)}
      </Text>
      <NewImgContainer>
        <NewTextContainer>
          <ImgText>{intl.formatMessage(i18n.team.text2)}</ImgText>
          <BottomText>
            <ImgName>{intl.formatMessage(i18n.team.sign)}</ImgName>
            <ImgPosition>{intl.formatMessage(i18n.team.position)}</ImgPosition>
          </BottomText>
        </NewTextContainer>
        <Img>
          <Image source="anna.png" alt="team" />
        </Img>
      </NewImgContainer>

      <Text fontSize="m" maxWidth="1100px" margin="2rem auto">
        {intl.formatMessage(i18n.team.extra)}{" "}
      </Text>
    </Container>
  )
}

export default Team
