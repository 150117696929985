import React from "react"
import Page from "../components/common/page"

import { ContentRow } from "../components/common/shared-components/layouts"
import Customer from "../components/page-components/aboutUs/customer/customer"
import StartVideo from "../components/page-components/aboutUs/start-video"
import AbtUs from "../components/page-components/aboutUs/who-are-we"
import WhyUs from "../components/page-components/aboutUs/why"
import Certificates from "../components/page-components/aboutUs/certificates"
import Team from "../components/page-components/aboutUs/team"


const CONTENT_ROWS = [
  [<AbtUs />],
  [<Customer />],
  [<StartVideo />],
  [<Team />],
  [<WhyUs />],
  [<Certificates />],
]

const AboutUs = () => {
  return (
    <Page title="aboutUs">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  )
}

export default AboutUs
