import styled from "styled-components"

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 4rem;
  background: #eee;
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: black;
  @media (max-width: 425px) {
    width: 100vw;
    margin: -50px;
    height: 300px;
    margin-left: -4rem;
  }
`

export const Video = styled.video`
  outline: none;
  width: 100vw;

  @media (min-width: 768px) {
    height: 60vh;
  }
`
export const Play = styled.div`
  position: absolute;
  opacity: 0.7;
  top: 63%;
  right: 50%;
  color: #eee;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    right: 40%;
    top: 40%;
  }
  @media (min-width: 768px) {
    top: 40%;
    right: 45%;
  }
`
